export default {
    settingsSystem: {
        headline: 'Systems',
        description: 'Change or add global system settings',
        tabs: {
            unit: 'Units',
            ordersInvoices: 'Orders & invoices',
            cloudStorage: 'Cloud Storage',
        },
        units: {
            list: {
                title: 'Units',
                emptyText: 'No units found',
                emptyAddText: 'Add your first unit',
                addItemBtn: 'Add item',
                nameColumn: 'Name',
                select: {
                    optionGeneral: 'General',
                    optionMetric: 'Metric system',
                    optionImperial: 'Imperial system',
                    optionAll: 'All units',
                },
                deleteConfirmation: 'Enter unit name to confirm',
            },
            create: {
                title: 'Create unit',
                headline: 'Create unit item',
                description: 'You can add your own units',
                sectionHeadline: 'General Information',
                sectionDescription:
                    'Please select the UNECE code for the new unit. You can search above or <a class="underline" target="_blank" href="https://unece.org/fileadmin/DAM/cefact/recommendations/rec21/rec21rev4_ecetrd309.pdf">check this list</a>.',
                nameLabel: 'Name *',
                codeLabel: 'UNECE Code *',
                eInvoiceSwitchLabel: 'Relevant for E-Invoices',
            },
            edit: {
                title: 'Edit unit',
                headline: 'Edit unit item',
            },
        },
        ordersInvoices: {
            title: 'Order & Invoices',
            saveSuccess: 'Settings saved successfully',
            generalInformation: {
                headline: 'General Information',
                referenceInHeader: {
                    label: 'Reference in header',
                    supportText:
                        'When this option is active, a reference will be added to the header.',
                },
            },
            proposals: {
                headline: 'Proposals',
                proposalsDeliveryTermsLabel: 'Delivery terms',
                proposalsPaymentTermsLabel: 'Payment terms',
                takeLastPorposalFromContact: {
                    label: 'Take over the last offer from contact',
                    supportText:
                        'Activate this option if you want the last offer to be automatically take over from the contact.',
                },
                invoiceOnCustomerAccept: {
                    label: 'Generate invoice on customer accept',
                    supportText:
                        'When you use an order link and your customer accepts the proposal online, should we auto-generate an invoice for you?',
                },
            },
            orderConfirmation: {
                headline: 'Order Confirmation',
                orderDeliveryTermsLabel: 'Delivery terms',
                paymentTermsLabel: 'Payment terms',
                takeLastOrderFromContact: {
                    label: 'Take over the last order from contact',
                    supportText:
                        'Activate this option if you want the last order to be automatically take over from the contact.',
                },
            },
            deliveryNotes: {
                headline: 'Delivery notes',
                takeLastDeliveryNoteFromContact: {
                    label: 'Take over the last delivery note from contact',
                    supportText:
                        'Activate this option if you want the last delivery note to be automatically take over from the contact.',
                },
            },
            reminder: {
                headline: 'Reminder',
                reminderPeriodLabel: 'Reminder period',
                reminderFeesLabel: 'Reminder fees',
            },
            invoice: {
                headline: 'Invoice',
                invoiceDueDateLabel: 'Due date',
                showPaymentDueDate: {
                    label: 'Show payment due date',
                    supportText:
                        'When this option is activated, payment due date will be printed on the invoice',
                },
                setDeliveryDateAutomatically: {
                    label: 'Set delivery date automatically',
                    supportText:
                        'When this option is active, our program will automatically use the current date as delivery date.',
                },
                adjustInvoiceDateAutomatically: {
                    label: 'Adjust invoice date automatically',
                    supportText:
                        "If this option is activated, the invoice date of drafts, if it is in the past, is automatically adjusted to today's date.",
                },
                takeOverLastInvoiceFromContact: {
                    label: 'Take over the invoice note from contact',
                    supportText:
                        'Activate this option if you want the last invoice to be automatically take over from the contact.',
                },
                automaticEnshrineInvoiceOnSending: {
                    label: 'Automatic enshrine invoices on sending',
                    supportText:
                        'When you send an invoice to your customer, we enshrine the invoice for you automatically.',
                },
                autoGenerateRecurringInvoices: {
                    label: 'Auto-generate recurring invoices',
                    supportText:
                        'Activate this option, when you want the system to automatically generate your recurring invoices.',
                },
                enableZUGFERDStandard: {
                    label: 'Enable the ZUGFeRD standard for invoices and credit notes',
                    supportText:
                        'Create all invoices and credit notes as valid ZUGFeRD documents. Additional Information will be embedded inside the pdf-document to make the document machine-readable.',
                },
            },
        },
        cloudStorage: {
            title: 'Cloud Storage',
            google: {
                headline: 'Google Drive',
                description:
                    'We will sync the documents with Cybooks. If a file is stored in Drive, we will start the document recognition.',
                buttonConnect: 'Connect Google Drive',
                buttonDisconnect: 'Disconnect Google Drive',
                buttonConfigBill: 'Config Bill',
                chooseFolder: 'Choose folder to sync',
            },
            billFolderModal: {
                title: 'Select folder',
                description:
                    'Please select the folder where you’re storing all your bills. We will use only the files within this folder to process your bills.',
                selectFolder: 'Select folder',
                billFolder: 'Bill Folder',
            },
        },
        message: {
            disconnectFailed: 'Disconnect failed!',
        },
    },
}
