<template>
    <base-modal
        close
        :show="social"
        :loading="state.loading"
        size="sm"
        @modal-close="closeModal"
    >
        <template #modal-icon>
            <div class="flex">
                <base-feature-icon
                    variant="success"
                    name="line-icons:files:file-check-02"
                />
            </div>
        </template>

        <div class="mb-3 flex flex-col">
            <h5 class="text-lg font-medium text-gray-900">
                {{ $t('settingsSystem.cloudStorage.billFolderModal.title') }}
            </h5>
            <p class="mb-5 mt-2 text-sm font-normal text-gray-500">
                {{
                    $t(
                        'settingsSystem.cloudStorage.billFolderModal.description'
                    )
                }}
            </p>

            <form-folder-select
                :label="
                    $t('settingsSystem.cloudStorage.billFolderModal.billFolder')
                "
                :placeholder="
                    $t('settingsSystem.cloudStorage.billFolderModal.billFolder')
                "
                v-model="state.folder"
                :options="state.folders"
                :tree-options="state.treeOptions"
                @on-change-tree="onChangeTrees"
            />
        </div>
        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width @click="onSaveFolder">
                    {{
                        $t(
                            'settingsSystem.cloudStorage.billFolderModal.selectFolder'
                        )
                    }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close'])

const { execute: executeGetListFolders } = useApi(
    '/api/cloud-storages/folders/{type}',
    'GET'
)
const { execute: executeGetFolder } = useApi(
    `/api/cloud-storages/folder/{type}`,
    'GET'
)
const { execute: executeSaveFolder } = useApi(
    `/api/cloud-storages/folders/{type}`,
    'POST'
)

const props = defineProps({
    social: {
        type: String,
        default: null,
    },
})

const state = reactive({
    loading: false,
    folders: [],
    folder: null,
    treeOptions: [],
})

watch(
    () => props.social,
    (value) => {
        if (!value) {
            state.folders = []
            return
        }

        getData(value)
    }
)

const getData = async (type) => {
    try {
        state.loading = true
        await getListFolders(type)
        await getFolder(type)
    } finally {
        state.loading = false
    }
}

const getFolder = (type) => {
    return executeGetFolder({
        params: {
            type,
        },
    }).then((folder) => {
        state.folder = folder?.folder_id
        state.treeOptions = folder?.folder_trees || []
    })
}

const getListFolders = (type) => {
    return executeGetListFolders({
        params: {
            type,
        },
    }).then((folders) => {
        state.folders = folders
    })
}

const onChangeTrees = (treeOptions) => {
    state.treeOptions = treeOptions
}

const closeModal = () => {
    emit('modal-close')
}

const onSaveFolder = () => {
    state.loading = true

    executeSaveFolder({
        params: {
            type: props.social,
        },
        data: {
            folder_id: state.folder,
            folder_trees: state.treeOptions,
        },
    })
        .then(() => {
            closeModal()
        })
        .finally(() => {
            state.loading = false
        })
}
</script>
